import React from "react";
import "./Crypto.css";
import BusinessForm from "../../BusinessForm/BusinessForm";

const Crypto = () => {
  return (
    <div className="Crypto">
      <BusinessForm
        GreenSuffix="Web3.0 "
        BlackSuffix=" Web Development"
        Quote="(Get a quote on a website - Its Free)"
        Description="Work with us to design a landing page for your crypto project. "
        FormType="Crypto-Project"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default Crypto;
