import "./WebAppDevelopment.css";
import BusinessForm from "../BusinessForm/BusinessForm";
import React from "react";

const WebAppDevelopment = () => {
  return (
    <div className="WebAppDevelopment  ">
      <BusinessForm
        GreenPrefix="App"
        Title=" Development"
        Quote="(Get a quote for developing a web app)"
        Description="We will work with you to design the app your company needs."
        FormType="Website Hosting"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default WebAppDevelopment;
