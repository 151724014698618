import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDXNKkkfxdH8pckXfx-hP4hT2eRGkq-_O0",
  authDomain: "meteq-4a246.firebaseapp.com",
  projectId: "meteq-4a246",
  storageBucket: "meteq-4a246.appspot.com",
  messagingSenderId: "913097100909",
  appId: "1:913097100909:web:c7b296e3bf3a3e5515f964",
  measurementId: "G-M0XQQBX7Y5",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
