import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="Footer">
      <Container>
        <Row>
          <Col>
            <a href="website-creation">Web Design</a>
          </Col>
          <Col>
            <a href="social-media-marketing">Social Media </a>
          </Col>
          <Col>
            <a href="technical-seo">Technical SEO</a>
          </Col>
        </Row>
        <Row>
          <Col>
            <a href="website-creation">Create Website</a>
          </Col>
          <Col>
            <a href="email-marketing">Email Marketing</a>
          </Col>
          <Col>
            <a href="web-app-development">Business Automation</a>
          </Col>
        </Row>
        <Row>
          <Col>
            <a href="web-maintenance">Maintain Website</a>
          </Col>
          <Col>
            <a href="content-marketing">Content Marketing</a>
          </Col>
          <Col>
            <a href="about-us">About Us</a>
          </Col>
        </Row>
        <h5>Copyright © 2022</h5>
      </Container>
    </div>
  );
};

export default Footer;
