import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WebDevIcon from "../../styles/Images/web-dev.svg";
import GoogleAdsIcon from "../../styles/Images/online-ads.svg";
import LinkBuildingIcon from "../../styles/Images/link-building.svg";
import SEOIcon from "../../styles/Images/technical-seo.svg";
import WebDesignIcon from "../../styles/Images/web-design.svg";
import ECommerceIcon from "../../styles/Images/ecommerce.svg";
import DigitalMarketingIcon from "../../styles/Images/digital-marketing.svg";
import FacebookMarketingIcon from "../../styles/Images/facebook-marketing.svg";
import TwitterMarketingIcon from "../../styles/Images/twitter-marketing.svg";
import KeywordFormulatingIcon from "../../styles/Images/keyword-formulating.svg";

import "./Services.css";
const Services = () => {
  return (
    <div className="Services">
      <div>
        <Container className="container">
          <Row>
            <Col xl={6} lg={12} sm={12} className="service-info-container">
              <h1 className="business-helper-heading">
                How We Can Help <br />
                <span className="green-header">Grow Your Business</span>.
              </h1>
              <div className="service-p">
                <h5>
                  A study concluded, 84% of people said they felt a business to
                  be more credible with a website. We believe{" "}
                  <span className="green">credibility</span> and{" "}
                  <span className="green">trust </span>
                  are at the <span className="green">heart</span> of every{" "}
                  <span className="green">small business</span> and it is our
                  goal to leverage technology to help{" "}
                  <span className="green">build stronger </span>businesses.
                </h5>
                <h5 className="second-p">
                  We <span className="green">bring your brand online</span> and
                  customers to your doorstep. Working with businesses one on
                  one, we enjoy building an online presence, from{" "}
                  <span className="green">website</span> design to{" "}
                  <span className="green">social media</span> marketing and
                  everything in between.
                </h5>
              </div>
            </Col>
            <Col className="services-col" xl={6} lg={12} sm={12}>
              <h1 className="service-heading">
                <span className="green-header">Services</span> We Provide.
              </h1>
              <Row className="services-row">
                <Col className="first-list" xl={6} lg={6} md={6} sm={6}>
                  <ul>
                    <li>
                      <span>
                        <img
                          className="icon"
                          src={GoogleAdsIcon}
                          alt="google-ads-icon"
                        />
                      </span>
                      Google Ads
                    </li>

                    <li>
                      <span>
                        <img
                          className="icon"
                          src={LinkBuildingIcon}
                          alt="link-building-icon"
                        />
                      </span>
                      Link Building
                    </li>

                    <li>
                      <span>
                        <img className="icon" src={SEOIcon} alt="SEO-icon" />
                      </span>
                      SEO Services
                    </li>
                    <li>
                      <span>
                        <img
                          className="icon"
                          src={WebDesignIcon}
                          alt="web-design-icon"
                        />
                      </span>
                      Web Design
                    </li>
                    <li>
                      <span>
                        <img
                          className="icon"
                          src={ECommerceIcon}
                          alt="ECommerce-icon"
                        />
                      </span>
                      eCommerce
                    </li>
                  </ul>
                </Col>
                <Col className="second-list" xl={6} lg={6} md={6} sm={6}>
                  <ul start="6">
                    <li>
                      <span>
                        <img
                          className="icon"
                          src={WebDevIcon}
                          alt="web-dev-icon"
                        />
                      </span>
                      Web Development
                    </li>
                    <li>
                      <span>
                        <img
                          className="icon"
                          src={DigitalMarketingIcon}
                          alt="digital-marketing-icon"
                        />
                      </span>
                      Digital Marketing
                    </li>
                    <li>
                      <span>
                        <img
                          className="icon"
                          src={FacebookMarketingIcon}
                          alt="facebook-marketing-icon"
                        />
                      </span>
                      Facebook Marketing
                    </li>

                    <li>
                      <span>
                        <img
                          className="icon"
                          src={TwitterMarketingIcon}
                          alt="twitter-marketing-icon"
                        />
                      </span>
                      Twitter Marketing
                    </li>
                    <li>
                      <span>
                        <img
                          className="icon"
                          src={KeywordFormulatingIcon}
                          alt="keyword-formulating-icon"
                        />
                      </span>
                      Keyword Formulating
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Services;
