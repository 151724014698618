import React from "react";
import "./GoogleMyBusiness.css";
import BusinessForm from "../BusinessForm/BusinessForm";
const GoogleMyBusiness = () => {
  return (
    <div className="GoogleMyBusiness">
      <BusinessForm
        GreenPrefix="Google "
        Title="Business Profile"
        Quote="(Get a FREE quote on Google business setup)"
        Description="Bring your business online so people can find it on Google."
        FormType="Google Business Setup"
        MessageSubject="Have you already set up your Google business account?  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default GoogleMyBusiness;
