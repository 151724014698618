import React from "react";
import "./HomeCare.css";
import BusinessForm from "../../BusinessForm/BusinessForm";

const HomeCare = () => {
  return (
    <div className="HomeCare">
      <BusinessForm
        Title="Create A "
        GreenSuffix="Home Care "
        BlackSuffix="Website"
        Quote="(Get a quote on a website - Its Free)"
        Description="We'll create a website that builds trust and credibility with clients."
        FormType="Construction"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default HomeCare;
