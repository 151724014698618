import React from "react";
import BusinessForm from "../BusinessForm/BusinessForm";

const GoogleBusinessAds = () => {
  return (
    <div className="GoogleBusinessAds">
      <BusinessForm
        GreenPrefix="Google "
        Title="Business Ads"
        Quote="(Get a quote on Google Ads - Its Free)"
        Description="Get your ads shown on Google and compete globally."
        FormType="Google Ads"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default GoogleBusinessAds;
