import React from "react";
import "./FinancialServices.css";
import BusinessForm from "../../BusinessForm/BusinessForm";

const FinancialServices = () => {
  return (
    <div className="FinancialServices">
      <BusinessForm
        GreenPrefix="Financial Services "
        Title="Website"
        Quote="(Get a quote on a website - Its Free)"
        Description="Have us create a website and automate services for your financial company."
        FormType="Financial-Services"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default FinancialServices;
