import "./FormTemplate.css";
import { useState, useEffect } from "react";
import Axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

const FormTemplate = ({
  Title,
  Quote,
  Description,
  GreenPrefix,
  FormType,
  MessageSubject,
  Button,
}) => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentWebsite, setCurrentWebsite] = useState("");
  const [information, setInformation] = useState("");
  const [formType, setFormType] = useState("");

  const sendForm = () => {
    Axios.post("https://api.meteq.io/api/posts/post", {
      name: name,
      companyName: companyName,
      email: email,
      phoneNumber: phoneNumber,
      currentWebsite: currentWebsite,
      information: information,
      formType: formType,
    });
    // window.location.reload();
  };

  const createFormType = () => {
    setFormType(FormType);
  };

  return (
    <div className="FormTemplate ">
      <Container className="container">
        <Row>
          <Col className="col col1">
            <h1>
              <span className="green">{GreenPrefix}</span>
              {Title}
            </h1>
            <div className="col1-info">
              <h5 className="green"> Email:</h5>
              <h5>
                <a href="mailto:everett.meteq@gmail.com">
                  everett.meteq@gmail.com
                </a>
              </h5>
              <h5 className="green">Phone Number:</h5>
              <h5>
                <a className="phone-link" href="tel:+1209-650-0996">
                  +1(209)650-0996
                </a>
              </h5>
              <p> {Quote} </p>
              <h5 className="info">{Description}</h5>
            </div>
          </Col>
          <Col className="col">
            <FloatingLabel className="form-label" label="Enter Name *">
              <Form.Control
                className="form-input"
                type="text"
                placeholder="Enter Name *"
                autoComplete="true"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </FloatingLabel>
            <FloatingLabel className="form-label" label="Enter Email *">
              <Form.Control
                className="form-input"
                type="email"
                placeholder="Enter Email *"
                autoComplete="true"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FloatingLabel>

            <textarea
              placeholder={MessageSubject}
              onChange={(e) => {
                createFormType();
                setInformation(e.target.value);
              }}
            ></textarea>

            <button
              type="submit"
              className="green-outline-btn"
              onClick={sendForm}
            >
              {Button}
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FormTemplate;
