import "./PostAutomation.css";
import React from "react";
import BusinessForm from "../BusinessForm/BusinessForm";
const PostAutomation = () => {
  return (
    <div className="PostAutomation ">
      <BusinessForm
        GreenPrefix="Post "
        Title="Automation"
        Quote="(Get a quote on Post Automation - Its Free)"
        Description="Enjoy more free time by automating your social media posts."
        FormType="Post Automation"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default PostAutomation;
