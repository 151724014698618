import React from "react";
import "./Specialty.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import businessImage from "../../styles/Images/tradingpost.jpg";

const Specialty = () => {
  return (
    <div className="Specialty">
      <Container className="container">
        <Row>
          <Col xl={6} lg={12} className="col">
            <img
              src={businessImage}
              alt="image-of-a-small-town-trading-post"
              className="pc-img"
            />
            {/* <img src={PcImage2} alt="image-of-a-laptop" className="pc2-img" /> */}
          </Col>
          <Col xl={6} lg={12} className="col">
            <h1>
              <span className="green-header">Scale</span> Your Business Through
              <span className="green-header"> Technology</span> and
              <span className="green-header"> Automation</span>.
            </h1>
            <div className="specialty-info">
              <h5>
                We design technology in accordance to your needs. So you get the
                benefits of the latest technology without the worry of
                maintaining it.
              </h5>

              <h1 className="specialty-header">
                Our <span className="green">Specialty</span>.
              </h1>
              <h5 className="specialty-h5">
                We pride ourselves on our ability to design and create websites
                that embody a company and its purpose.
              </h5>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Specialty;
