import "./BusinessForm.css";
import { useState, useEffect } from "react";
import Axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

const BusinessForm = ({
  Title,
  Quote,
  Description,
  GreenPrefix,
  BlackSuffix,
  GreenSuffix,
  FormType,
  MessageSubject,
  Button,
}) => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentWebsite, setCurrentWebsite] = useState("");
  const [information, setInformation] = useState("");
  const [formType, setFormType] = useState("");

  function refreshPage() {
    window.location.reload(false);
  }

  const sendForm = () => {
    Axios.post("https://api.meteq.io/api/posts/post", {
      name: name,
      companyName: companyName,
      email: email,
      phoneNumber: phoneNumber,
      currentWebsite: currentWebsite,
      information: information,
      formType: formType,
    }).then(() => {
      refreshPage();
    });
  };

  const createFormType = () => {
    setFormType(FormType);
  };

  return (
    <div className="BusinessForm form">
      <Container className="container">
        <Row>
          <Col className="col">
            <h1>
              <span className="green">{GreenPrefix}</span>
              {Title}
              <span className="green">{GreenSuffix}</span>
              <span>{BlackSuffix}</span>
            </h1>
            <p> {Quote} </p>
            <h5 className="info">{Description}</h5>
            <FloatingLabel className="form-label" label="Enter Name *">
              <Form.Control
                className="form-input"
                type="text"
                placeholder="Enter Name *"
                autoComplete="true"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </FloatingLabel>
            <textarea
              placeholder={MessageSubject}
              onChange={(e) => {
                createFormType();
                setInformation(e.target.value);
              }}
            ></textarea>
          </Col>
          <Col className="col">
            <FloatingLabel className="form-label" label="Enter Company Name *">
              <Form.Control
                className="form-input"
                type="text"
                placeholder="Enter Company Name *"
                autoComplete="true"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
            </FloatingLabel>
            <FloatingLabel className="form-label" label="Enter Email *">
              <Form.Control
                className="form-input"
                type="email"
                placeholder="Enter Email *"
                autoComplete="true"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel className="form-label" label="Enter Phone Number *">
              <Form.Control
                className="form-input"
                type="tel"
                placeholder="Enter Phone Number *"
                autoComplete="true"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel
              className="form-label"
              label="Enter Current Website URL"
            >
              <Form.Control
                className="form-input"
                type="text"
                placeholder="Enter Current Website URL"
                autoComplete="true"
                onChange={(e) => {
                  setCurrentWebsite(e.target.value);
                }}
              />
            </FloatingLabel>
            <FloatingLabel className="form-label" label="Monthly Budget $">
              <Form.Control
                className="form-input"
                type="text"
                placeholder="Monthly Budget $"
                autoComplete="true"
                onChange={(e) => {
                  setCurrentWebsite(e.target.value);
                }}
              />
            </FloatingLabel>

            <button
              type="submit"
              className="green-outline-btn"
              onClick={sendForm}
            >
              {Button}
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BusinessForm;
