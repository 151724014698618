import React from "react";
import "./Restaurants.css";
import BusinessForm from "../../BusinessForm/BusinessForm";

const Restaurants = () => {
  return (
    <div className="Restaurants">
      <BusinessForm
        Title="Build A Website For Your"
        GreenSuffix=" Restaurant "
        Quote="(Get a quote on a website - Its Free)"
        Description="Take online orders or simply show your menu on your custom website."
        FormType="Construction"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default Restaurants;
