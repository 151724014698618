import "./WebMaintenance.css";
import React from "react";
import BusinessForm from "../BusinessForm/BusinessForm";

const WebMaintenance = () => {
  return (
    <div className="WebMaintenance  ">
      <BusinessForm
        GreenPrefix="Website "
        Title="Maintenance"
        Quote="(Get a quote on Web Maintenance - Its Free)"
        Description="Need help with fixing your website or keeping it running?  "
        FormType="Website Maintenance"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default WebMaintenance;
