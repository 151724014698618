import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./CallToAction.css";
import bookMeetingImg from "../../styles/Images/upward.jpg";
const CallToAction = () => {
  return (
    <div className="CallToAction">
      <Container className="container">
        <Row className="book-meeting-container">
          <Col className="book-meeting-text">
            <h1>Book A Meeting</h1>
            <h1 className="green">To Get Started</h1>
            <h3>
              Speak to a professional to find out how we can get started on
              bringing your business online.
            </h3>
            <a href="book-a-meeting" className="book-meeting-a">
              <button className="green-outline-btn">BOOK A MEETING</button>
            </a>
          </Col>
          <Col className="book-img">
            <img src={bookMeetingImg} alt="image-of-growth-charts" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CallToAction;
