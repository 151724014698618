import React from "react";
import "./RetailStores.css";
import BusinessForm from "../../BusinessForm/BusinessForm";

const RetailStores = () => {
  return (
    <div className="RetailStores">
      <BusinessForm
        Title="Create An "
        GreenSuffix="eCommerce "
        BlackSuffix="Website"
        Quote="(Get a quote on a website - Its Free)"
        Description="Get an eCommerce website or just show off your products online to boost sales."
        FormType="Construction"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default RetailStores;
