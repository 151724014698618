import React from "react";
import BusinessForm from "../BusinessForm/BusinessForm";
const ContentMarketing = () => {
  return (
    <div className="ContentMarketing">
      <BusinessForm
        GreenPrefix="Content "
        Title="Marketing"
        Quote="(Get a quote on Content Marketing - Its Free)"
        Description="Let us help you create digital content for your brand."
        FormType="Content Marketing"
        MessageSubject="What platforms are you interested in creating content for?  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default ContentMarketing;
