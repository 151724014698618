import React from "react";
import BusinessForm from "../BusinessForm/BusinessForm";
const ContentAutomation = () => {
  return (
    <div className="ContentAutomation ">
      <BusinessForm
        GreenPrefix="Content "
        Title="Automation"
        Quote="(Get a quote on Content Automation - Its Free)"
        Description="Create digital content for your brand through automation."
        FormType="Content Automation"
        MessageSubject="What platforms are you interested in creating content for?  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default ContentAutomation;
