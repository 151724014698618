import "./TechnicalSEO.css";
import BusinessForm from "../BusinessForm/BusinessForm";
import React from "react";

const TechnicalSEO = () => {
  return (
    <div className="TechnicalSEO  ">
      <BusinessForm
        GreenPrefix="Technical"
        Title=" SEO Services"
        Quote="(Get a quote on Technical SEO - Its Free)"
        Description="Get your website optimized for search engines like google."
        FormType="Technical SEO"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default TechnicalSEO;
