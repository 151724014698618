import React from "react";
import "./PricingCard.css";
const PricingCard = ({
  title,
  pricing,
  recommended,
  imsSrc,
  description,
  benefit1,
  benefit2,
  benefit3,
  benefit4,
  benefit5,
}) => {
  return (
    <div className="PricingCard">
      <div className="card-container">
        <img />
        <h1>{title}</h1>
        <h2 className="green">{recommended}</h2>
        <h1>{pricing}</h1>
        <hr />
        <p>{description}</p>
        <ul>
          <li>{benefit1}</li>
          <li>{benefit2}</li>
          <li>{benefit3}</li>
          <li>{benefit4}</li>
          <li>{benefit5}</li>
        </ul>
        <a href="/other">
          <button className="green-filled-btn">CHOOSE PLAN</button>
        </a>
      </div>
    </div>
  );
};

export default PricingCard;
