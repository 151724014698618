import React from "react";
import "./NFTs.css";
import BusinessForm from "../../BusinessForm/BusinessForm";

const NFTs = () => {
  return (
    <div className="NFTs">
      <BusinessForm
        GreenPrefix="NFT Project"
        Title=" Website Creation "
        Quote="(Get a quote on a website - Its Free)"
        Description="Build the next innovative NFT on Cardano and we'll deploy your website and help you mint your NFTs."
        FormType="Construction"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default NFTs;
