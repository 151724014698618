import React from "react";
import BusinessForm from "../BusinessForm/BusinessForm";
import "./Quote.css";
const Quote = () => {
  return (
    <div className="Quote">
      <BusinessForm
        GreenPrefix="Find "
        Title="Software Solutions"
        Quote="(Get a quote on our services - Its Free)"
        Description="Software designed to fit your needs, and build confidence in your brand."
        FormType="Quote"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default Quote;
