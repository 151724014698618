import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";

import "./Business.css";
const Business = () => {
  const [business, setBusiness] = useState("");
  const [responded, setResponded] = useState(false);
  const [notes, setNotes] = useState("");
  const [quote, setQuote] = useState("");
  const { id } = useParams();

  useEffect(() => {
    findBusiness();
  }, []);

  const sendForm = () => {
    Axios.post(`https://api.meteq.io/api/posts/update/${business._id}`, {
      responded: responded,
      notes: notes,
      quote: quote,
    });
  };

  const findBusiness = () => {
    Axios.get(`https://api.meteq.io/api/posts/read/${id}`).then((res) => {
      const data = res.data;
      setBusiness(data);
    });
  };

  const deleteBusiness = () => {
    Axios.delete(`https://api.meteq.io/api/posts/delete/${business._id}`).then(
      (res) => {}
    );
  };

  return (
    <div className="Business ">
      <div className="container" key={business._id}>
        <h2 className="bold-header">Business:</h2>
        <h3 className="info">{business.companyName}</h3>
        <div>
          <h2 className="bold-header">Owners Name:</h2>
          <h3 className="info">{business.name}</h3>
          <h2 className="bold-header">Contact Info:</h2>
          <h3 className="info">
            Mail:{" "}
            <a className="green" href={`mailto:${business.email}`}>
              {business.email}
            </a>
          </h3>
          <h3 className="info">
            Phone:{" "}
            <a className="green" href={`tel:${business.phoneNumber}`}>
              {business.phoneNumber}
            </a>
          </h3>
          <h3 className="info">
            Website:{" "}
            <a className="green" href={business.currentWebsite}>
              {business.currentWebsite}
            </a>
          </h3>

          <h2 className="bold-header">Job Type: </h2>
          <h3 className="info">
            {business.formType} - {business.createdAt}
          </h3>
          <h2 className="bold-header">Message: </h2>
          <h3 className="info">{business.information}</h3>
          <hr />
          <div className="admin-input">
            <div className="form-group form-text">
              <label>Responded</label>
              <input
                type="checkbox"
                checked={responded}
                onChange={() => {
                  if (responded === true) {
                    setResponded(false);
                  } else {
                    setResponded(true);
                  }
                }}
              ></input>
            </div>

            <div className="form-group form-text">
              <label>Important Notes</label>
              <textarea
                defaultValue={business.notes ? business.notes : "Notes"}
                onChange={(business) => {
                  setNotes(business.target.value);
                }}
              ></textarea>
            </div>
            <div className="form-group form-text">
              <label>Quote Information</label>
              <textarea
                defaultValue={business.quote ? business.quote : "Quote"}
                onChange={(business) => {
                  setQuote(business.target.value);
                }}
              ></textarea>
            </div>
            <div className="form-group">
              <a href={`mailto:${business.email}`}>
                <button className="form-btn">Send Email</button>
              </a>
              <a href={`tel:${business.phoneNumber}`}>
                <button className="form-btn">Call Client</button>
              </a>
              <button
                className="form-btn"
                onClick={() => {
                  sendForm();
                }}
              >
                Save Info
              </button>
            </div>

            <button
              onClick={() => {
                deleteBusiness();
              }}
              className="red-outline-btn"
            >
              Delete Business Proposition
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
