import React from "react";
import BusinessForm from "../BusinessForm/BusinessForm";
const EmailMarketing = () => {
  return (
    <div className="EmailMarketing">
      <BusinessForm
        GreenPrefix="Email "
        Title="Marketing "
        Quote="(Get a quote on Email Marketing - Its Free)"
        Description="Let your customers know whats new with email marketing."
        FormType="Email Marketing"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default EmailMarketing;
