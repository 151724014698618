import React from "react";
import "./PricingTier.css";
import PricingCard from "../../components/PricingCard/PricingCard";
const PricingTier = () => {
  return (
    <div className="PricingTier">
      <div className="container">
        <PricingCard
          title="ESSENTIALS PACKAGE"
          pricing="$99/month"
          description="The essentials package combines top-notch features and affordability for a hassle-free online presence. "
          benefit1="Fully Responsive Website Design "
          benefit2="Search Engine Optimization  "
          benefit3="Domain Name Registration"
          benefit4="Website Hosting"
          benefit5="Branded Content Creation Including High-quality Images and copywriting "
        />
        <PricingCard
          title="ADVANCED PACKAGE"
          recommended="(RECOMMENDED)"
          pricing="$199/month"
          description="An interactive website experience, with added features that enhance user engagement and convenience."
          benefit1="ESSENTIALS PACKAGE"
          benefit2="+1 Website Redesign"
          benefit3="Dynamic Interactivity"
          benefit4="User Authentication & Customizable Forms"
          benefit5="API - Database Including Reviews, Products, Blogs, Ect"
        />
        <PricingCard
          title="Ultimate Package"
          pricing="$299+/month"
          description="Maximize your online earnings with advanced features that boost your sales and drive profitability."
          benefit1="ADVANCED PACKAGE"
          benefit2="E-Commerce & Product Listings"
          benefit3="STRIPE Payment Provider or Crypto Payments"
          benefit4="Advanced SEO - Keywords, Market Analytics "
          benefit5="Monthly Website Updates"
        />
      </div>
    </div>
  );
};

export default PricingTier;
