import React, { useState } from "react";
import "./Testimonial.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Axios from "axios";
import Form from "react-bootstrap/Form";

const Testimonial = () => {
  const [thankYou, setThankYou] = useState("");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [feedback, setFeedback] = useState("");

  function refreshPage() {
    window.location.reload(false);
  }

  const sendForm = () => {
    Axios.post("https://api.meteq.io/api/testimonials/post", {
      name: name,
      companyName: companyName,
      feedback: feedback,
    }).then(() => {
      refreshPage();
    });
  };

  return (
    <div className="Testimonial">
      <h1>
        Let Us Know About Your Experience With
        <span className="green"> Meteq</span>
      </h1>
      <FloatingLabel className="form-label" label="Your Name ">
        <Form.Control
          className="form-input"
          type="text"
          placeholder="Your Name "
          autoComplete="true"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </FloatingLabel>
      <FloatingLabel className="form-label" label="Your Company Name ">
        <Form.Control
          className="form-input"
          type="text"
          placeholder="Your Company Name "
          autoComplete="true"
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
        />
      </FloatingLabel>
      <textarea
        placeholder="How was your experience with our company?"
        onChange={(e) => {
          setFeedback(e.target.value);
        }}
      ></textarea>
      <button
        className="green-outline-btn"
        onClick={() => {
          sendForm();
          setThankYou("Thank You Soo Much For Your Feedback! 💚");
        }}
      >
        Submit Testimonial
      </button>
      <p className="thankyou">{thankYou}</p>
    </div>
  );
};

export default Testimonial;
