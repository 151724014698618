import "./WebsiteCreation.css";
import React from "react";
import BusinessForm from "../BusinessForm/BusinessForm";

const WebsiteCreation = () => {
  return (
    <div className="WebsiteCreation  ">
      <BusinessForm
        GreenPrefix="Web Design "
        Title="Services"
        Quote="(Get a quote on Web Design - Its Free)"
        Description="We'll design something that feels right for your business."
        FormType="Website Design Creation"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default WebsiteCreation;
