import React from "react";
import "./Veterinary.css";
import BusinessForm from "../../BusinessForm/BusinessForm";

const Veterinary = () => {
  return (
    <div className="Veterinary">
      <BusinessForm
        Title="Build A "
        GreenSuffix="Veterinary "
        BlackSuffix="Website"
        Quote="(Get a quote on a website - Its Free)"
        Description="Empower your clinic with a website and save time helping others."
        FormType="Construction"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default Veterinary;
