// modules
import "./styles/Main/Main.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserAuthContextProvider } from "./context/UserAuthContext";
// pages
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import ContactHeader from "./components/ContactHeader/ContactHeader";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Industries from "./pages/Industries/Industries";
import Services from "./pages/Services/Services";
import Specialty from "./pages/Specialty/Specialty";
import Reviews from "./pages/Reviews/Reviews";
import CallToAction from "./pages/CallToAction/CallToAction";
import Footer from "./pages/Footer/Footer";
import Business from "./pages/Business/Business";
import Admin from "./pages/Admin/Admin";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Testimonial from "./pages/Testimonial/Testimonial";
import Testimonials from "./pages/Testimonials/Testimonials";
// forms
import Contractors from "./forms/Industries/Contractors/Contractors";
import Crypto from "./forms/Industries/Crypto/Crypto";
import FinancialServices from "./forms/Industries/FinancialServices/FinancialServices";
import HomeCare from "./forms/Industries/HomeCare/HomeCare";
import NFTs from "./forms/Industries/NFTs/NFTs";
import Other from "./forms/Industries/Other/Other";
import Realty from "./forms/Industries/Realty/Realty";
import Restaurants from "./forms/Industries/Restaurants/Restaurants";
import RetailStores from "./forms/Industries/RetailStores/RetailStores";
import Veterinary from "./forms/Industries/Veterinary/Veterinary";
import ContactForm from "./forms/ContactForm/ContactForm.js/ContactForm";
import Quote from "./forms/Quote/Quote";
import BookAMeeting from "./forms/BookAMeeting/BookAMeeting";
import GoogleBusinessAds from "./forms/GoogleBusinessAds/GoogleBusinessAds";
import GoogleMyBusiness from "./forms/GoogleMyBusiness/GoogleMyBusiness";
import WebsiteCreation from "./forms/WebsiteCreation/WebsiteCreation";
import WebMaintenance from "./forms/WebMaintenance/WebMaintenance";
import OnPageSEO from "./forms/OnPageSEO/OnPageSEO";
import ContentMarketing from "./forms/ContentMarketing/ContentMarketing";
import EmailMarketing from "./forms/EmailMarketing/EmailMarketing";
import ContentAutomation from "./forms/ContentAutomation/ContentAutomation";
import PostAutomation from "./forms/PostAutomation/PostAutomation";
import SocialMediaMarketing from "./forms/SocialMediaMarketing/SocialMediaMarketing";
import WebAppDevelopment from "./forms/WebAppDevelopment/WebAppDevelopment";
import TechnicalSEO from "./forms/TechnicalSEO/TechnicalSEO";
import PricingTier from "./pages/PricingTier/PricingTier";
// import Private from "./pages/Private";
// import Login from "./pages/Login/Login";
// import Register from "./pages/Register/Register";
// Routing
// import PrivateRoute from "./components/routes/PrivateRoute";

export default function App() {
  return (
    <Router>
      <ContactHeader />
      <Navbar />
      <UserAuthContextProvider>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <Home />
                <Industries />
                <Services />
                <Specialty />
                <Reviews />
                <CallToAction />
                <Footer />
              </div>
            }
          />
          {/* <PrivateRoute exact path="/private" element={<Private />} /> */}
          <Route exact path="/website-creation" element={<WebsiteCreation />} />
          <Route exact path="/contact-form" element={<ContactForm />} />
          <Route exact path="/contractors" element={<Contractors />} />
          <Route exact path="/website-pricing-tier" element={<PricingTier />} />
          <Route exact path="/crypto" element={<Crypto />} />
          <Route exact path="/login" element={<Login />} />
          {/* <Route exact path="/sign-up" element={<SignUp />} /> */}
          <Route
            exact
            path="/financial-services"
            element={<FinancialServices />}
          />
          <Route exact path="/nfts" element={<NFTs />} />
          <Route exact path="/retail-store" element={<RetailStores />} />

          <Route exact path="/other" element={<Other />} />
          <Route exact path="/home-care" element={<HomeCare />} />
          <Route exact path="/real-estate" element={<Realty />} />
          <Route exact path="/restaurants" element={<Restaurants />} />
          <Route exact path="/retail-store" element={<RetailStores />} />
          <Route exact path="/veterinary" element={<Veterinary />} />
          <Route exact path="/web-maintenance" element={<WebMaintenance />} />
          <Route exact path="/technical-seo" element={<TechnicalSEO />} />
          <Route
            exact
            path="/social-media-marketing"
            element={<SocialMediaMarketing />}
          />
          <Route exact path="/testimonial" element={<Testimonial />} />
          <Route
            exact
            path="/approve-testimonials"
            element={
              <ProtectedRoute>
                <Testimonials />
              </ProtectedRoute>
            }
          />
          <Route exact path="/on-page-seo" element={<OnPageSEO />} />
          <Route exact path="/about-us" element={<About />} />
          <Route
            exact
            path="/web-app-development"
            element={<WebAppDevelopment />}
          />
          <Route exact path="/book-a-meeting" element={<BookAMeeting />} />
          <Route exact path="/free-quote" element={<Quote />} />
          <Route
            exact
            path="/admin"
            element={
              <ProtectedRoute>
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route exact path="/email-marketing" element={<EmailMarketing />} />
          <Route
            exact
            path="/content-marketing"
            element={<ContentMarketing />}
          />
          <Route
            exact
            path="/google-my-business"
            element={<GoogleMyBusiness />}
          />
          <Route
            exact
            path="/google-business-ads"
            element={<GoogleBusinessAds />}
          />
          <Route
            exact
            path="/content-automation"
            element={<ContentAutomation />}
          />
          <Route exact path="/post-automation" element={<PostAutomation />} />
          {/* <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} /> */}
          <Route path="/business/:id" exact element={<Business />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </UserAuthContextProvider>
    </Router>
  );
}
