import React from "react";
import "./Realty.css";
import BusinessForm from "../../BusinessForm/BusinessForm";

const Realty = () => {
  return (
    <div className="Realty">
      <BusinessForm
        Title="Create A "
        GreenSuffix=" Realty "
        BlackSuffix="Listings Website"
        Quote="(Get a quote on a website - Its Free)"
        Description="We'll build a website that allows you to post your available houses."
        FormType="Construction"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default Realty;
