import React, { useState, useEffect } from "react";
import Axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
// import Axios from "axios";
import Form from "react-bootstrap/Form";
import "./Testimonials.css";

const Testimonials = () => {
  const [review, setReview] = useState([]);
  const [imageLink, setImageLink] = useState("");
  const [approved, setApproved] = useState("");

  useEffect(() => {
    readReview();
  }, []);

  function refreshPage() {
    window.location.reload(false);
  }

  const readReview = () => {
    Axios.get("https://api.meteq.io/api/testimonials/read/").then((res) => {
      const data = res.data;
      setReview(data);
    });
  };

  const sendForm = (id) => {
    Axios.post(`https://api.meteq.io/api/testimonials/update/${id}`, {
      approved: approved,
      image: imageLink,
    }).then(() => {
      refreshPage();
    });
  };

  const deleteTestimonial = (id) => {
    Axios.delete(`https://api.meteq.io/api/testimonials/delete/${id}`).then(
      () => {
        refreshPage();
      }
    );
  };

  return (
    <div className="Testimonials">
      <h1>All User Testimonials</h1>
      {review.map((val, key) => {
        return (
          <div key={val._id} className="container">
            <h3>{val.companyName} </h3>
            <h4>{val.name} </h4>
            <h6>{val.feedback}</h6>
            <FloatingLabel className="form-label" label="Image URL ">
              <Form.Control
                className="form-input"
                type="text"
                placeholder="Image URL "
                autoComplete="true"
                onChange={(e) => {
                  setImageLink(e.target.value);
                }}
              />
            </FloatingLabel>
            <span>
              <input
                type="checkbox"
                onChange={(e) => {
                  setApproved(e.target.value);
                }}
              ></input>

              <button
                className="green-outline-btn"
                onClick={() => {
                  sendForm(val._id);
                }}
              >
                Submit
              </button>
            </span>
            <button
              className="red-outline-btn delete-btn"
              onClick={() => {
                deleteTestimonial(val._id);
              }}
            >
              DELETE TESTIMONIAL
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default Testimonials;
