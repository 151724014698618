import React from "react";
import BusinessForm from "../BusinessForm/BusinessForm";
import "./SocialMediaMarketing.css";

const SocialMediaMarketing = () => {
  return (
    <div className="SocialMediaMarketing">
      <BusinessForm
        GreenPrefix="Social Media "
        Title="Marketing"
        Quote="(Get a FREE quote on Social Media Marketing)"
        Description="Simplify Social media and get the benefits without the stress."
        FormType="Social Media Marketing"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default SocialMediaMarketing;
