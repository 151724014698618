import React from "react";
import "./Industries.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as Arrow } from "../../styles/Images/arrow-white.svg";
import resImg from "../../styles/Images/meal.jpg";
import newsPaper from "../../styles/Images/newspaper.jpg";
import nftImg from "../../styles/Images/crypto.jpg";
import officeImg from "../../styles/Images/mystery.jpg";
const Industries = () => {
  return (
    <div className="Industries">
      <div className="header-container">
        <h1>
          <span className="green">Small Businesses</span> Are The Backbone Of
          The World And We Help Them Transition Smoothly Into The
          <span className="green"> Digital Era</span>
        </h1>
      </div>
      <Container>
        <Row>
          <Col lg={3} md={6} sm={6} className="industry" id="Restaurants">
            <div className="bg-img">
              <img
                src={resImg}
                className="card-img"
                alt="image-of-a-breakfast-lunch-and-dinner-sign"
              />
              <div className="container-info">
                <h4>01</h4>
                <h2>Restaurants</h2>
                <h2>&</h2>
                <h2>Retail Stores</h2>
                <a href="other">
                  <Arrow className="arrow" />
                </a>
              </div>
            </div>
          </Col>

          <Col lg={3} md={6} sm={6} className="industry" id="Other">
            <div className="bg-img">
              <img
                src={officeImg}
                className="card-img"
                alt="image-of-a-man-working-on-a-blueprint"
              />
              <div className="container-info">
                <h4>03</h4>
                <h2>Contractors</h2>
                <h2>&</h2>
                <h2>Builders</h2>
                <a href="contractors">
                  <Arrow className="arrow" id="other-arrow" />
                </a>
              </div>
            </div>
          </Col>

          <Col lg={3} md={6} sm={6} className="industry" id="Veterinary">
            <div className="bg-img">
              <img
                src={newsPaper}
                className="card-img"
                alt="image-of-a-business-newspaper"
              />
              <div className="container-info">
                <h4>02</h4>
                <h2>Real Estate</h2>
                <h2>&</h2>
                <h2>Finances</h2>
                <a href="real-estate">
                  <Arrow className="arrow" />
                </a>
              </div>
            </div>
          </Col>

          <Col lg={3} md={6} sm={6} className="industry" id="Crypto">
            <div className="bg-img">
              <img
                src={nftImg}
                className="card-img"
                alt="image-of-bored-ape-yacht-club-nft"
              />
              <div className="container-info">
                <h4>04</h4>
                <h2>Crypto</h2>
                <h2>&</h2>
                <h2>NFTs</h2>
                <a href="nfts">
                  <Arrow className="arrow" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Industries;
