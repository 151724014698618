import "./BookAMeeting.css";
import React from "react";
import BusinessForm from "../BusinessForm/BusinessForm";
const BookAMeeting = () => {
  return (
    <div className="BookAMeeting">
      <BusinessForm
        GreenPrefix="Schedule "
        Title="A Meeting"
        Quote="(Find a good time to book a meeting - Its Free)"
        Description="Lets meet so we can leverage solutions that fit your business."
        FormType="Book A Meeting"
        MessageSubject="What can we help you with? *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default BookAMeeting;
