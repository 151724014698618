import { useState } from "react";
import "./Navbar.css";
import greenPhone from "../../styles/Images/green-phone.png";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../../styles/Images/black-green-logo.png";
export default function Navbar() {
  const [showLinks, setShowLinks] = useState(false);

  return (
    <div className="Navbar">
      <ul className="nav-links" id={showLinks ? "nav-active" : "nav-hidden"}>
        <a className="nav-phone" href="tel:+1209-651-0996">
          <h5>
            <img alt="phone-logo" src={greenPhone} className="phone-logo" />{" "}
            (209)651-0996
          </h5>
        </a>

        <Dropdown className="dropdown">
          <Dropdown.Toggle className="dropdown-name" variant="light">
            Industries
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu">
            <Dropdown.Item className="dropdown-item" href="restaurants">
              Restaurants
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="retail-store">
              Retail Stores
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="contractors">
              Contractors
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="home-care">
              Home Care
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="veterinary">
              Veterinary
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="real-estate">
              Realty
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="financial-services">
              Financial Services
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="crypto">
              Crypto
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="nfts">
              NFTs
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="other">
              Other
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="dropdown">
          <Dropdown.Toggle className="dropdown-name" variant="light">
            Web Development
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu">
            <Dropdown.Item className="dropdown-item" href="website-creation">
              Website Design
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="web-app-development">
              Web App Development
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="web-maintenance">
              Website Maintenance
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="dropdown">
          <Dropdown.Toggle className="dropdown-name" variant="light">
            SEO
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu">
            <Dropdown.Item className="dropdown-item" href="technical-seo">
              Technical SEO
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="on-page-seo">
              On-Page SEO
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="dropdown">
          <Dropdown.Toggle className="dropdown-name" variant="light">
            Marketing
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu">
            <Dropdown.Item
              className="dropdown-item"
              href="social-media-marketing"
            >
              Social Media Marketing
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="email-marketing">
              Email Marketing
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="content-marketing">
              Content Marketing
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="dropdown">
          <Dropdown.Toggle className="dropdown-name" variant="light">
            Google Business
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu">
            <Dropdown.Item className="dropdown-item" href="google-my-business">
              Google My Business
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="google-business-ads">
              Google Business Ads
            </Dropdown.Item>
            {/* <Dropdown.Item className="dropdown-item" href="post-automation">
              Post Automation
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="content-automation">
              Content Automation
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="dropdown">
          <Dropdown.Toggle className="dropdown-name" variant="light">
            About
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu">
            <Dropdown.Item className="dropdown-item" href="about-us">
              About Us
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" href="contact-form">
              Contact Us
            </Dropdown.Item>
            {/* <Dropdown.Item className="dropdown-item" href="#/action-1">
              Team
            </Dropdown.Item> */}
            {/* <Dropdown.Item className="dropdown-item" href="#/action-1">
              Career
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </ul>
      <a href="/">
        <h1 className="nav-title">
          <img src={logo} alt="meteq-logo" className="logo" />
        </h1>
      </a>
      <div className="burger" onClick={() => setShowLinks(!showLinks)}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </div>
  );
}
