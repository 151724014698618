import React, { useState, useEffect } from "react";
import "./About.css";
import Axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Footer from "../Footer/Footer";
import Col from "react-bootstrap/Col";
const About = () => {
  const [Testimonials, setTestimonials] = useState([]);
  const [num, setNum] = useState(0);
  let randomNum = 0;
  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = () => {
    Axios.get("https://api.meteq.io/api/testimonials/read/").then((res) => {
      const data = res.data;
      setTestimonials(data);
      randomNum = generateRandomNumber(0, Testimonials.length);
      return randomNum;
    });
  };

  function generateRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  return (
    <div className="About">
      <div className="about-us">
        <h1>About Us</h1>
        <p>
          <span className="green"> Meteq is a systems company </span>that is
          based out of Tuolumne, California. We help bring your brand to the
          digital world by creating websites, web applications, and leveraging
          digital marketing along side strong SEO to help grow your business.
          Most importantly{" "}
          <span className="green">
            {" "}
            we build systems that help you scale your business{" "}
          </span>{" "}
          to your hearts content, all at an affordable cost.
        </p>
      </div>
      <div className="our-why">
        <h1>Our Why</h1>
        <p>
          What drive us is our passion for{" "}
          <span className="green"> seeing small businesses grow </span> and
          scale to be something we can all benefit from. That is why here at
          <span className="green"> Meteq</span> we create the{" "}
          <span className="green">Most Effective Teqnologies </span>for a
          business to expand digitally and impact the world in the best way
          possible.
        </p>
      </div>
      <div className="started-container">
        <div className="started">
          {/* <div className="services">
          <h2>What We Offer Our Clients?</h2>
          <ul>
            <div>
              <a className="services-link">
                <li className="service">Web Design Services</li>
              </a>
            </div>
          </ul>
        </div> */}

          <h1>Where It All Started?</h1>
          <p>
            <span className="green">Growing up in the small town </span>
            everyone works at local businesses and everyone depends on systems
            that empower the community. That is what gives the small town a
            glimmer of perfection. Every business does their part to contribute
            to creating a stronger and healthier community. That is where we
            started,
            <span className="green">
              {" "}
              with the desire to build modern systems{" "}
            </span>
            like websites, online retail storefronts, digital menus and local
            digital marketing campaigns all to bring the small businesses online
            so they can continue to{" "}
            <span className="green">
              compete in the ever expanding online world.
            </span>
          </p>
        </div>
      </div>
      <div className="testimonials-container">
        <h1 className="testimonial-title">What Our Clients Say About Us?</h1>
        <div className="testimonials">
          <h3>
            "
            {Testimonials[randomNum]
              ? Testimonials[num].feedback
              : "Loading..."}
            "
          </h3>
          <h3 className="customer-name">
            -{Testimonials[randomNum] ? Testimonials[num].name : "Loading..."}{" "}
          </h3>
        </div>
      </div>

      <div className="values">
        <h1>Our Core Values</h1>
        <Container>
          <Row>
            <Col>
              <a href="https://www.merriam-webster.com/dictionary/Adaptability">
                <div className="value-container">
                  <h3 className="value">Adaptability</h3>
                </div>
              </a>

              <a href="https://www.merriam-webster.com/dictionary/Integrity">
                <div className="value-container">
                  <h3 className="value">Integrity</h3>
                </div>
              </a>

              <a href="https://www.merriam-webster.com/dictionary/Ethical">
                <div className="value-container">
                  <h3 className="value">Ethical</h3>
                </div>
              </a>
            </Col>
            <Col>
              <a href="https://www.merriam-webster.com/dictionary/Altruism">
                <div className="value-container">
                  <h3 className="value">Altruism</h3>
                </div>
              </a>

              <a href="https://www.merriam-webster.com/dictionary/Honesty">
                <div className="value-container">
                  <h3 className="value">Honesty</h3>
                </div>
              </a>

              <a href="https://www.merriam-webster.com/dictionary/Freedom">
                <div className="value-container">
                  <h3 className="value">Freedom</h3>
                </div>
              </a>
            </Col>
            <Col>
              <a href="https://www.merriam-webster.com/dictionary/Agility">
                <div className="value-container">
                  <h3 className="value">Agility</h3>
                </div>
              </a>

              <a href="https://www.merriam-webster.com/dictionary/Credibility">
                <div className="value-container">
                  <h3 className="value">Credibility</h3>
                </div>
              </a>

              <a href="https://www.merriam-webster.com/dictionary/Optimism">
                <div className="value-container">
                  <h3 className="value">Optimism</h3>
                </div>
              </a>
            </Col>
            <Col>
              <a href="https://www.merriam-webster.com/dictionary/Humility">
                <div className="value-container">
                  <h3 className="value">Humility</h3>
                </div>
              </a>

              <a href="https://www.merriam-webster.com/dictionary/Dedication">
                <div className="value-container">
                  <h3 className="value">Dedication</h3>
                </div>
              </a>

              <a href="https://www.merriam-webster.com/dictionary/Tolerance">
                <div className="value-container">
                  <h3 className="value">Tolerance</h3>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default About;
