import React from "react";
import BusinessForm from "../BusinessForm/BusinessForm";
const OnPageSEO = () => {
  return (
    <div className="OnPageSEO">
      <BusinessForm
        GreenPrefix="On Page "
        Title="SEO Services"
        Quote="(Get a quote on SEO - Its Free)"
        Description="Get more traffic on your website through on page SEO"
        FormType="On Page SEO"
        MessageSubject="Message *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default OnPageSEO;
