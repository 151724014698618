import "./Review.css";
import React from "react";
const Review = ({ reviewersName, reviewersJob, reviewBody, reviewImg }) => {
  return (
    <div className="Review">
      <img className="review-img" src={reviewImg} alt="testimonial-profile" />
      <div className="container">
        <h2 className="review-header">{reviewersJob}</h2>
        <h5 className="review-body">"{reviewBody}"</h5>
        <h4 className="review-header">-{reviewersName}</h4>
        <a href="/testimonial">
          <button className=" green-outline-btn">Leave A Testimonial</button>
        </a>
      </div>
    </div>
  );
};

export default Review;
