import React from "react";
import "./Contractors.css";
import BusinessForm from "../../BusinessForm/BusinessForm";

const Contractors = () => {
  return (
    <div className="Contractors">
      <BusinessForm
        GreenPrefix="Build "
        Title="A Construction Website"
        Quote="(Get a quote on a website - Its Free)"
        Description="Expand your client base by showing off your work with a stunning website."
        FormType="Construction"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default Contractors;
