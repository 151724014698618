import React from "react";
import "./Other.css";
import BusinessForm from "../../BusinessForm/BusinessForm";

const Other = () => {
  return (
    <div className="Other">
      <BusinessForm
        Title="Put"
        GreenSuffix=" Your Brand "
        BlackSuffix="Online With A Website"
        Quote="(Get a quote on a website - Its Free)"
        Description="We'll work with you to build a website that suites your company."
        FormType="Construction"
        MessageSubject="Message  *"
        Button="Get A Free Quote"
      />
    </div>
  );
};

export default Other;
