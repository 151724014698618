import React from "react";
import "./PageNotFound.css";
const PageNotFound = () => {
  return (
    <div className="PageNotFound">
      <div className="container">
        <p>You Must Be Lost</p>
        <p>This Page Doesn't Exist</p>
        <h1>
          ERROR <span className="green">404</span>
        </h1>
      </div>
    </div>
  );
};

export default PageNotFound;
