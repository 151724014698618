import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./Admin.css";

const Admin = () => {
  const [notifications, setNotifications] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [responded, setResponded] = useState(false);
  const [notes, setNotes] = useState("");
  const [quote, setQuote] = useState("");

  useEffect(() => {
    readNotifications();
  }, []);

  const sendForm = (id) => {
    Axios.post(`https://api.meteq.io/api/posts/update/${id}`, {
      responded: responded,
      notes: notes,
      quote: quote,
    });
  };

  const readNotifications = () => {
    Axios.get("https://api.meteq.io/api/posts/read/").then((res) => {
      const data = res.data;
      setNotifications(data);
    });
  };

  const deleteCustomerEntry = (id) => {
    Axios.delete(`https://api.meteq.io/api/posts/delete/${id}`).then(() => {});
  };

  return (
    <div className="Admin">
      <a href="/approve-testimonials">
        <h1>Testimonials</h1>
      </a>
      <h1>Current Business Leads</h1>
      {notifications.map((val, key) => {
        return (
          <div key={val._id}>
            <Container className="container">
              <Row>
                <Col>
                  <div className="a-container">
                    <a
                      className="business-page-link green-a"
                      href={`/business/${val._id}`}
                    >
                      View This Company
                    </a>
                  </div>

                  <button
                    className="info-btn"
                    onClick={() => {
                      if (showInfo === true) {
                        setShowInfo(false);
                      } else {
                        setShowInfo(true);
                      }
                    }}
                  >
                    {showInfo ? "-" : "+"}
                  </button>
                  <h2 className="bold-header">Companies Name:</h2>
                  <h3 className="info">{val.companyName}</h3>
                  <div className={`${showInfo ? "" : "dont-show"}`}>
                    <h2 className="bold-header">Owners Name:</h2>
                    <h3 className="info">{val.name}</h3>
                    <h2 className="bold-header">Contact Info:</h2>
                    <h3 className="info">
                      Mail: <a href={`mailto:${val.email}`}>{val.email}</a>
                    </h3>
                    <h3 className="info">
                      Phone:{" "}
                      <a href={`tel:${val.phoneNumber}`}>{val.phoneNumber}</a>
                    </h3>
                    <h3 className="info">
                      Website:{" "}
                      <a href={val.currentWebsite}>{val.currentWebsite}</a>
                    </h3>

                    <h2 className="bold-header">Job Type: </h2>
                    <h3 className="info">
                      {val.formType} - {val.createdAt}
                    </h3>
                    <h2 className="bold-header">Message: </h2>
                    <h3 className="info">{val.information}</h3>
                    <hr />
                    <div className="admin-input">
                      <div className="form-group form-text">
                        <label>Responded</label>
                        <input
                          type="checkbox"
                          checked={responded}
                          onChange={() => {
                            if (responded === true) {
                              setResponded(false);
                            } else {
                              setResponded(true);
                            }
                          }}
                        ></input>
                      </div>

                      <div className="form-group form-text">
                        <label>Important Notes</label>
                        <textarea
                          defaultValue={val.notes ? val.notes : "Notes"}
                          onChange={(val) => {
                            setNotes(val.target.value);
                          }}
                        ></textarea>
                      </div>
                      <div className="form-group form-text">
                        <label>Quote Information</label>
                        <textarea
                          defaultValue={val.quote ? val.quote : "Quote"}
                          onChange={(val) => {
                            setQuote(val.target.value);
                          }}
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <a href={`mailto:${val.email}`}>
                          <button className="form-btn">Send Email</button>
                        </a>
                        <a href={`tel:${val.phoneNumber}`}>
                          <button className="form-btn">Call Client</button>
                        </a>
                        <button
                          className="form-btn"
                          onClick={() => {
                            sendForm(val._id);
                          }}
                        >
                          Save Info
                        </button>
                        <button
                          onClick={() => {
                            deleteCustomerEntry(val._id);
                          }}
                          className="red-outline-btn delete-btn"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        );
      })}
    </div>
  );
};

export default Admin;
