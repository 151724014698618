import React from "react";
import "./ContactHeader.css";
import whitePhone from "../../styles/Images/white-phone.png";
import logo from "../../styles/Images/white-and-green-logo.png";
import Button from "react-bootstrap/Button";

const ContactHeader = () => {
  return (
    <div className="ContactHeader">
      <a href="/">
        <h1 className="contact-header-info">
          <img className="logo" src={logo} alt="meteq-logo" />
        </h1>
      </a>
      <a href="tel:+1209-651-0996">
        <h1 className="contact-header-info number">
          <img atl="phone-logo" src={whitePhone} className="phone-logo" />{" "}
          (209)651-0996
        </h1>
      </a>
      <a href="/free-quote">
        <button className="contact-header-info light-btn">
          REQUEST A FREE QUOTE
        </button>
      </a>
    </div>
  );
};

export default ContactHeader;
