import "./ContactForm.css";
import React from "react";
import FormTemplate from "../../FormTemplate/FormTemplate";
const ContactForm = () => {
  return (
    <div className="ContactForm">
      <FormTemplate
        Title="Contact Us"
        // Quote="(Find a good time to book a meeting - Its Free)"
        Description="We're thankful for your interest in our company. If you have any questions or want to leave any feedback you can always email & call us, or message us directly with this form. "
        FormType="Contact Us"
        MessageSubject="Message *"
        Button="Send Us A Message"
      />
    </div>
  );
};

export default ContactForm;
