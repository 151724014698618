import React, { useState, useEffect } from "react";
import Axios from "axios";
import Review from "../../components/Review/Review";

import "./Reviews.css";
const Reviews = () => {
  const [Testimonials, setTestimonials] = useState([]);
  const [num, setNum] = useState(0);

  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = () => {
    Axios.get("https://api.meteq.io/api/testimonials/read/").then((res) => {
      const data = res.data;
      setTestimonials(data);
    });
  };

  const filteredTestimonials = Testimonials.filter((val) => {
    return val.approved === "on";
  });

  const nextReview = () => {
    if (num === filteredTestimonials.length - 1) {
      setNum(filteredTestimonials.length - 1);
    } else {
      setNum(num + 1);
    }
  };
  const previousReview = () => {
    if (num === 0) {
      setNum(0);
    } else {
      setNum(num - 1);
    }
  };

  return (
    <div className="Reviews">
      <div>
        <Review
          className="review"
          reviewImg={
            filteredTestimonials[0]
              ? filteredTestimonials[num].image
              : "Loading..."
          }
          reviewersName={
            filteredTestimonials[0]
              ? filteredTestimonials[num].name
              : "Loading..."
          }
          reviewersJob={
            filteredTestimonials[0]
              ? filteredTestimonials[num].companyName
              : "Loading..."
          }
          reviewBody={
            filteredTestimonials[0]
              ? filteredTestimonials[num].feedback
              : "Loading..."
          }
        />
      </div>
      <button
        className="review-img-btn green-outline-btn prev-btn"
        onClick={previousReview}
      >
        <p>Prev</p>
      </button>
      ..............
      <button
        className="review-img-btn green-outline-btn next-btn"
        onClick={nextReview}
      >
        <p>Next</p>
      </button>
    </div>
  );
};

export default Reviews;
