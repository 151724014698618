import React from "react";
import "./Home.css";
import cityVideo from "../../styles/Images/plants-video.mp4";
const Home = () => {
  return (
    <div className="Home">
      <video src={cityVideo} type="video/mp4" autoPlay loop muted />
      <div className="home-info">
        <h1>
          <span className="green-header">Locally Grown </span> Software
        </h1>
        <h5>
          With <span className="green-header">Local Businesses</span> In Mind
        </h5>
        <a href="book-a-meeting">
          <button className="green-filled-btn white-hover">
            GET FREE CONSULTATION
          </button>
        </a>
      </div>
    </div>
  );
};

export default Home;
